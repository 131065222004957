import { useQueries, useQuery, UseQueryResult } from 'react-query';
import {
  findMetricsCharts,
  FindMetricsChartsArguments,
  FindMetricsChartsResponse,
} from '../../../api/metrics';

export const useFindMetricsChartsQueries = (
  args: FindMetricsChartsArguments[],
): UseQueryResult<FindMetricsChartsResponse>[] =>
  useQueries(
    args.map((args2) => ({
      queryKey: ['metrics', 'charts', args2.params],
      queryFn: () => findMetricsCharts(args2),
    })),
  );

const useFindMetricsChartsQuery = (
  args: FindMetricsChartsArguments,
): UseQueryResult<FindMetricsChartsResponse> =>
  useQuery(['metrics', 'charts', args.params], () => findMetricsCharts(args));

export default useFindMetricsChartsQuery;
