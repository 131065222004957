import React from 'react';
import { parseAsString, useQueryState } from 'nuqs';
import { ChartsData } from './types';
import withNuqsAdapter from '../../common/hooks/withNuqsAdapter';
import { MetricsCharts } from '../../common/types/Atlas';
import { downloadChartExport, parseAsMetricsCharts } from './utils';
import MetricsControls from './metrics-controls';
import MetricsChart from './metrics-chart';

const MetricsDashboard: React.FC<{
  organizationName: string;
  chartsData: ChartsData;
  minDate: string;
  maxDate: string;
}> = ({ organizationName, chartsData, minDate, maxDate }) => {
  const [dateStart, setDateStart] = useQueryState(
    'date_start',
    parseAsString.withDefault(minDate),
  );

  const [dateEnd, setDateEnd] = useQueryState(
    'date_end',
    parseAsString.withDefault(maxDate),
  );
  const [chartType, setChartType] = useQueryState(
    'chart_type',
    parseAsMetricsCharts.withDefault(MetricsCharts.LineCharts),
  );

  const handleDateStartChange = (value: string) => {
    void setDateStart(value);
  };

  const handleDateEndChange = (value: string) => {
    void setDateEnd(value);
  };

  const handleChartTypeChange = (value: string) => {
    void setChartType(parseAsMetricsCharts.parse(value));
  };

  const chartsDataArray = [
    {
      organizationName,
      data: chartsData.series,
    },
  ];

  const handleExportClick = () => {
    downloadChartExport(chartType, null, chartsDataArray, dateStart, dateEnd);
  };

  return (
    <div className="tw-space-y-4">
      <MetricsControls
        minDate={minDate}
        maxDate={maxDate}
        dateStart={dateStart}
        dateEnd={dateEnd}
        chartType={chartType}
        onDateStartChange={handleDateStartChange}
        onDateEndChange={handleDateEndChange}
        onChartTypeChange={handleChartTypeChange}
        onExportClick={handleExportClick}
      />

      <MetricsChart
        chartType={chartType}
        chartsData={chartsDataArray}
        dateStart={dateStart}
        dateEnd={dateEnd}
      />
    </div>
  );
};

export default withNuqsAdapter(MetricsDashboard);
