/* eslint-disable import/prefer-default-export */

export const isDateInRange = (
  date: Date | string | number,
  rangeStart?: Date | string,
  rangeEnd?: Date | string,
) => {
  if (rangeStart && new Date(date).getTime() < new Date(rangeStart).getTime()) {
    return false;
  }

  if (rangeEnd && new Date(date).getTime() > new Date(rangeEnd).getTime()) {
    return false;
  }

  return true;
};
