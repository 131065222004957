import { throttle } from 'lodash-es';

interface BatchJob<T, R> {
  payload: T;
  resolve: (value: R) => void;
  reject: (value?: unknown) => void;
  promise: Promise<R>;
}

interface Batcher<T, R> {
  (payload: T): Promise<R>;
}

interface BatchPerformer<T, R> {
  (jobs: Array<BatchJob<T, R>>): Promise<Array<R>> | Array<R>;
}

interface BatchResolver<T, R> {
  (job: BatchJob<T, R>, result: Array<R>): R | undefined;
}

const jobBatchGenerator = <T, R>(
  jobs: Array<BatchJob<T, R>>,
  maxJobsPerBatch: number,
): Array<Array<BatchJob<T, R>>> => {
  const batches: Array<Array<BatchJob<T, R>>> = [];

  while (jobs.length > 0) {
    batches.push(jobs.splice(0, maxJobsPerBatch));
  }

  return batches;
};

const executor = async <T, R>(
  jobs: Array<BatchJob<T, R>>,
  performer: BatchPerformer<T, R>,
  resolver: BatchResolver<T, R>,
  maxJobsPerBatch: number,
) => {
  const batches = jobBatchGenerator(jobs, maxJobsPerBatch);

  await batches.reduce(async (prevPromise, batch) => {
    await prevPromise;

    try {
      const results = await performer(batch);

      batch.forEach((job) => {
        try {
          const result = resolver(job, results);

          if (result) {
            job.resolve(result);
          } else {
            job.reject();
          }
        } catch (e) {
          job.reject(e);
        }
      });
    } catch (e) {
      batch.forEach((job) => job.reject(e));
    }
  }, Promise.resolve());
};

const makeJob = <T, R>(payload: T): BatchJob<T, R> => {
  let resolve: undefined | ((value: R | PromiseLike<R>) => void);
  let reject: undefined | ((reason?: any) => void);
  const promise = new Promise<R>((res, rej) => {
    resolve = res;
    reject = rej;
  });

  if (!resolve || !reject) {
    throw new Error('Failed to create job');
  }

  return {
    payload,
    resolve,
    reject,
    promise,
  };
};

function batcher<T, R>(
  performer: BatchPerformer<T, R>,
  resolver: BatchResolver<T, R>,
  waitMs = 100,
  maxJobsPerBatch = 20,
): Batcher<T, R> {
  const jobs: BatchJob<T, R>[] = [];

  const execute = throttle(
    () => executor(jobs, performer, resolver, maxJobsPerBatch),
    waitMs,
    { leading: false, trailing: true },
  );

  return (payload) => {
    const job = makeJob<T, R>(payload);
    jobs.push(job);

    void execute();

    return job.promise;
  };
}

export default batcher;
