import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import {
  searchOrganizations,
  SearchReflectionsParams,
  SearchReflectionsResponse,
} from '../../../api/organizations/search';

const useSearchOrganizationInfiniteQuery = (
  params: Omit<SearchReflectionsParams, 'page'>,
  options?: {
    enabled?: boolean;
    cacheTime?: number;
    staleTime?: number;
  },
): UseInfiniteQueryResult<SearchReflectionsResponse> =>
  useInfiniteQuery(
    ['organizations', 'search', 'infinite', params],
    (context) => {
      const page = (context.pageParam ?? 1) as number;

      return searchOrganizations({
        params: { ...params, page },
      });
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.metadata.current_page + 1;
        if (nextPage > lastPage.metadata.total_pages) {
          return null;
        }
        return nextPage;
      },
      ...options,
    },
  );

export default useSearchOrganizationInfiniteQuery;
