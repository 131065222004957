import React from 'react';
import { OrganizationID } from '../../common/types/Atlas';
import ResourcesSearch from '../search/resource-search';
import OrganizationName from '../../common/components/organization-name';

const OrganizationsList: React.FC<{
  organizationIds: OrganizationID[];
  onAdd: (orgId: OrganizationID) => void;
  onRemove: (orgId: OrganizationID) => void;
}> = ({ organizationIds, onAdd, onRemove }) => (
  <div className="tw-flex tw-flex-col tw-gap-4 tw-iris-element-box tw-p-4">
    <div>
      <h4 className="tw-card-title">{__('Organizations')}</h4>
      <p>{__('Select up to 4 organizations at a time.')}</p>
    </div>

    <ResourcesSearch
      filter={{ type: 'organization' }}
      onResultClick={(r) => onAdd(r.data.id)}
      placeholder={__('Select organization')}
      closeOnClick
    />

    <ul className="tw-border tw-rounded-box tw-flex-1">
      {organizationIds.map((orgId) => (
        <li key={orgId} className="tw-flex tw-justify-between tw-py-2">
          <div className="tw-font-medium tw-text-lg tw-px-4">
            <OrganizationName organizationId={orgId} />
          </div>

          <div className="tw-px-2">
            <button
              type="button"
              className="tw-btn tw-btn-ghost tw-btn-xs"
              onClick={() => onRemove(orgId)}
            >
              {__('Remove')}
            </button>
          </div>
        </li>
      ))}
    </ul>
  </div>
);

export default OrganizationsList;
