import React from 'react';
import { ChartsData } from './types';
import { MetricsCharts } from '../../common/types/Atlas';
import MetricsAggregateChart from './chart-types/metrics-aggregate-chart';
import MetricsLineChart from './chart-types/metrics-line-chart';

const MetricsChart: React.FC<{
  chartType: MetricsCharts;
  chartMetric?: string;
  chartsData: Array<{
    organizationName: string;
    data: ChartsData['series'];
  }>;
  dateStart?: string;
  dateEnd?: string;
}> = ({ chartType, chartMetric, chartsData, dateStart, dateEnd }) => {
  const render = () => {
    switch (chartType) {
      case MetricsCharts.Aggregates:
        return (
          <MetricsAggregateChart
            chartMetric={chartMetric}
            chartsData={chartsData}
            dateStart={dateStart}
            dateEnd={dateEnd}
          />
        );

      case MetricsCharts.LineCharts:
        return (
          <MetricsLineChart
            chartMetric={chartMetric}
            chartsData={chartsData}
            dateStart={dateStart}
            dateEnd={dateEnd}
          />
        );

      default:
        return null;
    }
  };

  return <div className="tw-iris-element-box tw-px-4 tw-py-6">{render()}</div>;
};

export default MetricsChart;
