import { parseAsStringEnum } from 'nuqs';
import { ChartsData } from './types';
import { isNotEmpty } from '../../common/utils/utils';
import { MetricsCharts } from '../../common/types/Atlas';
import { convertToCsv, downloadCsv } from '../../common/utils/csv';
import { isDateInRange } from '../../common/utils/date';

export const aggregateValues = (values: Record<string, number>[]) => {
  const totals = values.reduce<Record<string, number>>((acc, data) => {
    Object.entries(data).forEach(([key, value]) => {
      acc[key] ||= 0;
      acc[key] += value;
    });

    return acc;
  }, {});

  return Object.entries(totals).map(([name, value]) => ({
    name,
    value,
  }));
};

const aggregateChartsData = (
  chartsSeriesData: ChartsData['series'],
  dateStart?: string,
  dateEnd?: string,
) => {
  const aggregatedData = new Map<string, number>();

  chartsSeriesData.forEach((data) => {
    const total = data.data.reduce((acc, [date, value]) => {
      if (isDateInRange(date, dateStart, dateEnd)) {
        return acc + value;
      }

      return acc;
    }, 0);

    aggregatedData.set(data.name, total);
  });

  return aggregatedData;
};

export const groupChartsDataByDate = (
  chartsSeriesData: ChartsData['series'],
) => {
  const groupedData = new Map<string, Record<string, number>>();

  chartsSeriesData.forEach((data) => {
    data.data.forEach(([key, value]) => {
      const entry = groupedData.get(key);

      if (entry) {
        entry[data.name] = (entry[data.name] ?? 0) + value;
      } else {
        groupedData.set(key, { [data.name]: value });
      }
    });
  });

  return Object.fromEntries(groupedData);
};

export const groupChartMetricByDateAndOrganization = (
  chartsData: Array<{
    organizationName: string;
    data: ChartsData['series'];
  }>,
  chartMetric: string,
) => {
  const groupedData = new Map<string, Record<string, number>>();

  chartsData.forEach(({ data, organizationName }) => {
    const metricData = data.find((s) => s.name === chartMetric);

    if (metricData) {
      metricData.data.forEach(([date, value]) => {
        const group = groupedData.get(date) ?? {};
        groupedData.set(date, {
          ...group,
          [organizationName]: value,
        });
      });
    }
  });

  return Object.fromEntries(groupedData);
};

const generateAggregatesDataCsv = (
  chartsSeriesData: ChartsData['series'],
  dateStart?: string,
  dateEnd?: string,
) => {
  const fields = chartsSeriesData.map(({ name }) => name);

  const aggregates = aggregateChartsData(chartsSeriesData, dateStart, dateEnd);
  const filteredData = Object.fromEntries(
    [...aggregates.entries()].filter(([date]) =>
      isDateInRange(date, dateStart, dateEnd),
    ),
  );

  return convertToCsv(filteredData, { fields });
};

const sortGroupedData = (chartsSeriesData: ChartsData['series']) =>
  [...Object.entries(groupChartsDataByDate(chartsSeriesData))]
    .map(([date, values]) => ({
      dateAsString: date,
      date: new Date(date),
      values,
    }))
    .sort((a, b) => a.date.valueOf() - b.date.valueOf());

const generateLineChartsDataCsv = (
  chartsSeriesData: ChartsData['series'],
  dateStart?: string,
  dateEnd?: string,
) => {
  const fields = chartsSeriesData.map(({ name }) => name);

  const data = sortGroupedData(chartsSeriesData)
    .filter(({ date }) => isDateInRange(date, dateStart, dateEnd))
    .map(({ dateAsString, values }) => ({
      date: dateAsString,
      ...values,
    }));

  return convertToCsv(data, { fields: ['date', ...fields] });
};

const downloadAggregatesDataCsv = (
  chartsSeriesData: ChartsData['series'],
  dateStart?: string,
  dateEnd?: string,
) =>
  downloadCsv(
    generateAggregatesDataCsv(chartsSeriesData, dateStart, dateEnd),
    [__('Metrics Totals'), dateStart, dateEnd].filter(isNotEmpty).join(' '),
  );

const downloadLineChartsDataCsv = (
  chartsSeriesData: ChartsData['series'],
  dateStart?: string,
  dateEnd?: string,
) =>
  downloadCsv(
    generateLineChartsDataCsv(chartsSeriesData, dateStart, dateEnd),
    [__('Weekly Metrics'), dateStart, dateEnd].filter(isNotEmpty).join(' '),
  );

export const parseAsMetricsCharts = parseAsStringEnum<MetricsCharts>(
  Object.values(MetricsCharts),
);

const generateAggregateMetricCsv = (
  chartMetric: string,
  chartsData: Array<{
    organizationName: string;
    data: ChartsData['series'];
  }>,
  dateStart?: string,
  dateEnd?: string,
) => {
  const groupedData = Object.entries(
    groupChartMetricByDateAndOrganization(chartsData, chartMetric),
  );
  const filteredData = groupedData.filter(([k]) =>
    isDateInRange(k, dateStart, dateEnd),
  );

  const totals = aggregateValues(filteredData.map(([, v]) => v));

  const orgNameKey = __('Organization');
  const csvData = totals.reduce<Array<Record<string, string | number>>>(
    (acc, total) => [
      ...acc,
      {
        [orgNameKey]: total.name,
        [chartMetric]: total.value,
      },
    ],
    [],
  );

  return convertToCsv(csvData);
};

const downloadAggregateMetricCsv = (
  chartMetric: string,
  chartsData: Array<{
    organizationName: string;
    data: ChartsData['series'];
  }>,
  dateStart?: string,
  dateEnd?: string,
) => {
  const csv = generateAggregateMetricCsv(
    chartMetric,
    chartsData,
    dateStart,
    dateEnd,
  );

  downloadCsv(
    csv,
    [__('Metric Totals'), `(${chartMetric})`, dateStart, dateEnd]
      .filter(isNotEmpty)
      .join(' '),
  );
};

const generateLineChartMetricCsv = (
  chartMetric: string,
  chartsData: Array<{
    organizationName: string;
    data: ChartsData['series'];
  }>,
  dateStart?: string,
  dateEnd?: string,
) => {
  const groupedData = Object.entries(
    groupChartMetricByDateAndOrganization(chartsData, chartMetric),
  );
  const filteredData = groupedData.filter(([k]) =>
    isDateInRange(k, dateStart, dateEnd),
  );

  const dateKey = __('Date');
  const csvData = filteredData.map(([date, values]) => ({
    [dateKey]: date,
    ...values,
  }));

  return convertToCsv(csvData);
};

const downloadLineChartMetricCsv = (
  chartMetric: string,
  chartsData: Array<{
    organizationName: string;
    data: ChartsData['series'];
  }>,
  dateStart?: string,
  dateEnd?: string,
) => {
  const csv = generateLineChartMetricCsv(
    chartMetric,
    chartsData,
    dateStart,
    dateEnd,
  );

  downloadCsv(
    csv,
    [__('Weekly Metrics'), `(${chartMetric})`, dateStart, dateEnd]
      .filter(isNotEmpty)
      .join(' '),
  );
};

export const downloadChartExport = (
  chartType: MetricsCharts,
  chartMetric: string | null,
  chartsData: Array<{
    organizationName: string;
    data: ChartsData['series'];
  }>,
  dateStart?: string,
  dateEnd?: string,
) => {
  switch (chartType) {
    case MetricsCharts.Aggregates: {
      switch (typeof chartMetric) {
        case 'string': {
          downloadAggregateMetricCsv(
            chartMetric,
            chartsData,
            dateStart,
            dateEnd,
          );

          break;
        }

        default: {
          const chartsSeriesData = chartsData.flatMap(({ data }) => data);

          downloadAggregatesDataCsv(chartsSeriesData, dateStart, dateEnd);
        }
      }

      break;
    }

    case MetricsCharts.LineCharts: {
      switch (typeof chartMetric) {
        case 'string': {
          downloadLineChartMetricCsv(
            chartMetric,
            chartsData,
            dateStart,
            dateEnd,
          );

          break;
        }

        default: {
          const chartsSeriesData = chartsData.flatMap(({ data }) => data);

          downloadLineChartsDataCsv(chartsSeriesData, dateStart, dateEnd);
        }
      }

      break;
    }

    default:
      break;
  }
};
