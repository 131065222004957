import { useQuery, UseQueryResult } from 'react-query';
import {
  findOrganizations,
  FindOrganizationsResponse,
} from '../../../api/organizations';
import { OrganizationID } from '../../../types/Atlas';
import batcher from '../../../api/batcher';

type FindOrganizationResponse = FindOrganizationsResponse['data'][number];

const findOrganization = batcher<OrganizationID, FindOrganizationResponse>(
  async (jobs) => {
    const result = await findOrganizations({
      params: { id: jobs.map((job) => job.payload) },
    });

    return result.data;
  },
  (job, results) => results.find((org) => org.id === job.payload),
);

const useFindOrganizationQuery = (
  id: OrganizationID,
  options?: { staleTime?: number; enabled?: boolean },
): UseQueryResult<FindOrganizationResponse> =>
  useQuery(['organizations', id], () => findOrganization(id), {
    staleTime: 60000,
    ...options,
  });

export default useFindOrganizationQuery;
