import React from 'react';
import {
  parseAsArrayOf,
  parseAsInteger,
  parseAsString,
  useQueryState,
} from 'nuqs';
import dayjs from 'dayjs';
import withNuqsAdapter from '../../common/hooks/withNuqsAdapter';
import { MetricsCharts, OrganizationID } from '../../common/types/Atlas';
import { downloadChartExport, parseAsMetricsCharts } from './utils';
import { withQueryClient } from '../../common/hooks/withQueryClient';
import OrganizationsList from './organizations-list';
import MetricsControls from './metrics-controls';
import MetricsChart from './metrics-chart';
import { useFindMetricsChartsQueries } from '../../common/hooks/api/metrics/useFindMetricsChartsQuery';

const SuperMetricsDashboard: React.FC = () => {
  const [chartType, setChartType] = useQueryState(
    'chart_type',
    parseAsMetricsCharts.withDefault(MetricsCharts.LineCharts),
  );

  const [chartMetric, setChartMetric] = useQueryState(
    'chart_metric',
    parseAsString,
  );

  const [organizationIds, setOrganizationIds] = useQueryState(
    'organization_id',
    parseAsArrayOf(parseAsInteger).withDefault([]),
  );

  const handleChartTypeChange = (value: string) => {
    void setChartType(parseAsMetricsCharts.parse(value));
  };

  const handleChartMetricChange = (value: string) => {
    void setChartMetric(value || null);
  };

  const handleAddOrganizationFilter = (orgId: OrganizationID) => {
    void setOrganizationIds(
      Array.from(new Set([...(organizationIds || []), orgId])),
    );
  };

  const handleRemoveOrganizationFilter = (orgId: OrganizationID) => {
    if (!organizationIds) {
      return;
    }

    void setOrganizationIds(organizationIds.filter((o) => o !== orgId));
  };

  const queries = useFindMetricsChartsQueries(
    organizationIds.map((orgId) => ({
      params: { organization_id: orgId },
    })),
  );

  const dates = queries.reduce<{
    minDate?: Date;
    maxDate?: Date;
  }>((acc, query) => {
    if (!query.isSuccess) return acc;

    const { min_date: minDate, max_date: maxDate } = query.data.data;

    if (minDate && (!acc.minDate || minDate < acc.minDate)) {
      acc.minDate = minDate;
    }

    if (maxDate && (!acc.maxDate || maxDate < acc.maxDate)) {
      acc.maxDate = maxDate;
    }

    return acc;
  }, {});

  const [dateStart, setDateStart] = useQueryState('date_start', parseAsString);
  const [dateEnd, setDateEnd] = useQueryState('date_end', parseAsString);

  const handleDateStartChange = (value: string) => {
    void setDateStart(value);
  };

  const handleDateEndChange = (value: string) => {
    void setDateEnd(value);
  };

  const minDate = dates.minDate
    ? dayjs(dates.minDate).format('YYYY-MM-DD')
    : undefined;
  const maxDate = dates.maxDate
    ? dayjs(dates.maxDate).format('YYYY-MM-DD')
    : undefined;

  const allChartsSeriesData = queries.flatMap((query) => {
    if (!query.isSuccess) {
      return [];
    }

    return query.data.data.charts_data.series;
  });

  const allChartsData = queries.flatMap((query) => {
    if (!query.isSuccess) {
      return [];
    }

    return [
      {
        organizationId: query.data.data.organization_id,
        organizationName: query.data.data.organization_name,
        data: query.data.data.charts_data.series,
      },
    ];
  });

  const handleExportClick = () => {
    downloadChartExport(
      chartType,
      chartMetric,
      allChartsData,
      dateStart ?? minDate,
      dateEnd ?? maxDate,
    );
  };

  const metricNames = Array.from(
    new Set(allChartsSeriesData.flatMap((series) => series.name)),
  );

  return (
    <div className="tw-grid tw-grid-cols-4 tw-gap-8">
      <OrganizationsList
        organizationIds={organizationIds ?? []}
        onAdd={handleAddOrganizationFilter}
        onRemove={handleRemoveOrganizationFilter}
      />

      <div className="tw-col-span-3 tw-space-y-4">
        <MetricsControls
          minDate={minDate}
          maxDate={maxDate}
          dateStart={dateStart ?? minDate}
          dateEnd={dateEnd ?? maxDate}
          chartType={chartType}
          chartMetric={chartMetric ?? undefined}
          chartMetricOptions={metricNames}
          onChartMetricChange={handleChartMetricChange}
          onDateStartChange={handleDateStartChange}
          onDateEndChange={handleDateEndChange}
          onChartTypeChange={handleChartTypeChange}
          onExportClick={handleExportClick}
        />

        <MetricsChart
          chartType={chartType}
          chartMetric={chartMetric ?? undefined}
          chartsData={allChartsData}
          dateStart={dateStart ?? minDate}
          dateEnd={dateEnd ?? maxDate}
        />
      </div>
    </div>
  );
};

export default withNuqsAdapter(withQueryClient(SuperMetricsDashboard));
