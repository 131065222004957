import * as Atlas from '../../../types/Atlas';
import { inRange } from '../../../utils/utils';
import { parseModelObjectArray } from '../../api-parser';
import ApiError from '../../error';
import { jsonHeaders, PaginationParams } from '../../routes';
import { toQuerystring } from '../../utils';

export const searchOrganizationsRoute = '/api/v0/organizations/search';

export type SearchReflectionsParams = PaginationParams & {
  search: string;
};

export interface SearchReflectionsArguments {
  params: SearchReflectionsParams;
}

export interface SearchReflectionsResponse {
  data: Atlas.CondensedGroup[];
  metadata: Atlas.Metadata;
}

export const searchOrganizations = async (
  args: SearchReflectionsArguments,
): Promise<SearchReflectionsResponse> => {
  const url = [searchOrganizationsRoute, toQuerystring(args.params)].join('?');

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  });

  if (!inRange(response.status, 200, 300)) {
    throw new ApiError(response.status, 'searchOrganizations');
  }

  const body = await response.json();
  const data = parseModelObjectArray<Atlas.CondensedGroup>(body.data);
  const metadata = body.metadata as Atlas.Metadata;

  return { data, metadata };
};
