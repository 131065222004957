import React, { ComponentProps } from 'react';
import { ResourceSearchResult } from '../../common/types';
import GroupSearchResults from './group-search-results';
import ReflectionSearchResults from './reflection-search-results';
import SearchResultsContainer from './common/search-results-container';
import OrganizationSearchResults from './organization-search-results';

export type ResourceFilter =
  | {
      type: 'group';
      filter?: ComponentProps<typeof GroupSearchResults>['filter'];
    }
  | {
      type: 'reflection';
    }
  | {
      type: 'organization';
    };

const ResourceSearchResults: React.FC<{
  filter: ResourceFilter;
  query: string;
  onResultClick: (result: ResourceSearchResult) => void;
}> = (props) => {
  const { filter, query, onResultClick } = props;

  switch (filter.type) {
    case 'group':
      return (
        <SearchResultsContainer>
          <GroupSearchResults
            query={query}
            filter={filter.filter}
            onResultClick={onResultClick}
          />
        </SearchResultsContainer>
      );

    case 'reflection':
      return (
        <SearchResultsContainer>
          <ReflectionSearchResults
            query={query}
            onResultClick={onResultClick}
          />
        </SearchResultsContainer>
      );

    case 'organization':
      return (
        <SearchResultsContainer>
          <OrganizationSearchResults
            query={query}
            onResultClick={onResultClick}
          />
        </SearchResultsContainer>
      );

    default:
      return null;
  }
};

export default ResourceSearchResults;
