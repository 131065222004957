import { useState } from 'react';
import { useDebounce } from 'react-use';

const useDebounceValue = <T>(value: T, ms?: number): [T, boolean] => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useDebounce(
    () => {
      setDebouncedValue(value);
    },
    ms,
    [value],
  );

  const isDebouncing = value !== debouncedValue;

  return [debouncedValue, isDebouncing];
};

export default useDebounceValue;
