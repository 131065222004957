import React from 'react';
import TimelineChart from '../../../common/components/timeline-chart';
import { isDateInRange } from '../../../common/utils/date';
import { ChartsData } from '../types';
import {
  groupChartsDataByDate,
  groupChartMetricByDateAndOrganization,
} from '../utils';

const MetricsLineChart: React.FC<{
  chartMetric?: string;
  chartsData: Array<{
    organizationName: string;
    data: ChartsData['series'];
  }>;
  dateStart?: string;
  dateEnd?: string;
}> = ({ chartMetric, chartsData, dateStart, dateEnd }) => {
  switch (typeof chartMetric) {
    case 'string': {
      const groupedData = Object.entries(
        groupChartMetricByDateAndOrganization(chartsData, chartMetric),
      );
      const filteredData = groupedData.filter(([k]) =>
        isDateInRange(k, dateStart, dateEnd),
      );
      const lineChartData = filteredData.map(([date, values]) => ({
        ...values,
        date: new Date(date).valueOf(),
      }));

      return <TimelineChart data={lineChartData} height={500} />;
    }

    default: {
      const chartsSeriesData = chartsData.flatMap(({ data }) => data);
      const groupedData = Object.entries(
        groupChartsDataByDate(chartsSeriesData),
      );
      const filteredData = groupedData.filter(([k]) =>
        isDateInRange(k, dateStart, dateEnd),
      );

      const lineChartData = filteredData.map(([date, values]) => ({
        ...values,
        date: new Date(date).valueOf(),
      }));

      return <TimelineChart data={lineChartData} height={500} />;
    }
  }
};

export default MetricsLineChart;
