import * as Atlas from '../../types/Atlas';
import { parseModelObjectArray } from '../api-parser';
import ApiError from '../error';
import { jsonHeaders, PaginationParams } from '../routes';
import { toQuerystring } from '../utils';

export const findOrganizationsRoute = '/api/v0/organizations';

export type FindOrganizationsParams = PaginationParams & {
  id: Atlas.OrganizationID | Atlas.OrganizationID[];
};

export interface FindOrganizationsArguments {
  params: FindOrganizationsParams;
}

export interface FindOrganizationsResponse {
  data: Atlas.Group[];
  metadata: Atlas.Metadata;
}

export const findOrganizations = async (
  args: FindOrganizationsArguments,
): Promise<FindOrganizationsResponse> => {
  const url = [findOrganizationsRoute, toQuerystring(args.params)].join('?');

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  });

  if (!response.ok) {
    throw new ApiError(response.status, 'findOrganizations');
  }

  const body = await response.json();
  const data = parseModelObjectArray<Atlas.Group>(body.data);
  const metadata = body.metadata as Atlas.Metadata;

  return { data, metadata };
};
