import React, { useState } from 'react';
import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { pickExtendedColor } from '../utils/colors';

const dateFormatter = new Intl.DateTimeFormat(navigator.language, {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
});

const formatDate = (value: string | number) =>
  dateFormatter.format(new Date(value));

const dateKey = 'date';

const TimelineChart = <T extends { date: number }>({
  data,
  height,
}: {
  data: T[];
  height: number;
}): JSX.Element => {
  const [hidden, setHidden] = useState<Record<string, boolean>>({});

  const keys = Array.from(
    data.reduce((acc, datum) => {
      Object.keys(datum).forEach((key) => {
        if (key === dateKey) {
          return;
        }
        acc.add(key);
      });

      return acc;
    }, new Set<string>()),
  );

  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart
        height={height}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />

        <XAxis
          dataKey={dateKey}
          type="number"
          tickFormatter={formatDate}
          domain={['dataMin', 'dataMax']}
        />
        <YAxis />

        <Tooltip labelFormatter={formatDate} />

        <Legend
          onClick={(e: { value: string }) => {
            const { value } = e;

            setHidden((prev) => ({
              ...prev,
              [value]: !prev[value],
            }));
          }}
        />

        {keys.map((dataKey, index) => (
          <Line
            key={dataKey}
            type="monotone"
            dataKey={dataKey}
            dot={false}
            stroke={pickExtendedColor(index)}
            hide={hidden[dataKey]}
          />
        ))}

        <Brush
          dataKey={dateKey}
          height={30}
          stroke="hsl(var(--p))"
          tickFormatter={formatDate}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TimelineChart;
