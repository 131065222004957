import React from 'react';
import useSearchValue from '../../../hooks/useSearchValue';
import { ResourceSearchResult } from '../../common/types';
import SearchResultsError from './common/search-results-error';
import SearchResultsLoader from './common/search-results-loader';
import SearchResultsSuccess from './common/search-results-success';
import useSearchOrganizationInfiniteQuery from '../../../../../common/hooks/api/organizations/useSearchOrganizationsInfiniteQuery';

const OrganizationSearchResults: React.FC<{
  query: string;
  onResultClick: (result: ResourceSearchResult) => void;
}> = ({ query, onResultClick }) => {
  const search = useSearchValue(query);

  const searchQuery = useSearchOrganizationInfiniteQuery(
    {
      search: query,
    },
    {
      enabled: search.enabled,
      staleTime: 60000,
    },
  );

  switch (searchQuery.status) {
    case 'success': {
      const results = searchQuery.data.pages.reduce<ResourceSearchResult[]>(
        (acc, data) => [
          ...acc,
          ...data.data.map<ResourceSearchResult>((organization) => ({
            type: 'organization',
            data: organization,
          })),
        ],
        [],
      );

      return (
        <SearchResultsSuccess
          results={results}
          isLoading={searchQuery.isFetching}
          hasMoreResults={searchQuery.hasNextPage}
          onLoadMoreClick={searchQuery.fetchNextPage}
          onResultClick={onResultClick}
        />
      );
    }

    case 'error':
      return <SearchResultsError />;

    default:
      return <SearchResultsLoader />;
  }
};

export default OrganizationSearchResults;
