import { Parser } from '@json2csv/plainjs';

export const convertToCsv = (
  data: object | object[],
  opts?: { fields?: string[] },
) => {
  const parser = new Parser({ fields: opts?.fields });
  const csv = parser.parse(data);
  return csv;
};

export const downloadCsv = (csvString: string, filename = 'data.csv') => {
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};
