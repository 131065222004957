import React from 'react';
import SearchResultButton from './common/search-result-button';
import * as Atlas from '../../../../../common/types/Atlas';

const SearchOrganizationResult = (props: {
  group: Atlas.CondensedGroup;
  onClick: () => void;
}) => {
  const { group, onClick } = props;

  if (group.is_organization) {
    const avatarSrc =
      group.logo?.viewable_s3uri?.url ||
      '/assets/img/organization_placeholder.png';

    return (
      <SearchResultButton onClick={onClick}>
        <div className="tw-avatar tw-flex-shrink-0 tw-w-16 tw-flex tw-justify-center">
          <div className="tw-rounded-lg tw-w-10">
            <img src={avatarSrc} alt={__('Organization avatar')} />
          </div>
        </div>

        <div className="tw-text-left">
          <div className="tw-text-sm tw-opacity-60">{__('Organization')}</div>
          <div>{group.name}</div>
        </div>
      </SearchResultButton>
    );
  }

  const avatarSrc =
    group.logo?.viewable_s3uri?.url ||
    '/assets/img/reflections/display-picture-group-blue.png';

  return (
    <SearchResultButton onClick={onClick}>
      <div className="tw-avatar tw-flex-shrink-0 tw-w-16 tw-flex tw-justify-center">
        <div className="tw-rounded-lg tw-w-10">
          <img src={avatarSrc} alt={__('Group avatar')} />
        </div>
      </div>

      <div className="tw-text-left">
        <div>{group.name}</div>
      </div>
    </SearchResultButton>
  );
};

export default SearchOrganizationResult;
