import React from 'react';
import HorizontalBarChart from '../../../common/components/horizontal-bar-chart';
import { isDateInRange } from '../../../common/utils/date';
import { ChartsData } from '../types';
import {
  groupChartsDataByDate,
  aggregateValues,
  groupChartMetricByDateAndOrganization,
} from '../utils';

const MetricsAggregateChart: React.FC<{
  chartMetric?: string;
  chartsData: Array<{
    organizationName: string;
    data: ChartsData['series'];
  }>;
  dateStart?: string;
  dateEnd?: string;
}> = ({ chartMetric, chartsData, dateStart, dateEnd }) => {
  switch (typeof chartMetric) {
    case 'string': {
      const groupedData = Object.entries(
        groupChartMetricByDateAndOrganization(chartsData, chartMetric),
      );
      const filteredData = groupedData.filter(([k]) =>
        isDateInRange(k, dateStart, dateEnd),
      );

      const barChartData = aggregateValues(filteredData.map(([, v]) => v));

      return <HorizontalBarChart data={barChartData} height={500} />;
    }

    default: {
      const chartsSeriesData = chartsData.flatMap(({ data }) => data);
      const groupedData = Object.entries(
        groupChartsDataByDate(chartsSeriesData),
      );
      const filteredData = groupedData.filter(([k]) =>
        isDateInRange(k, dateStart, dateEnd),
      );

      const barChartData = aggregateValues(filteredData.map(([, v]) => v));

      return <HorizontalBarChart data={barChartData} height={500} />;
    }
  }
};

export default MetricsAggregateChart;
