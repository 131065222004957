import React from 'react';
import useFindOrganizationQuery from '../hooks/api/organizations/useFindOrganizationQuery';
import { OrganizationID } from '../types/Atlas';

const OrganizationName: React.FC<{
  organizationId: OrganizationID;
}> = ({ organizationId }) => {
  const query = useFindOrganizationQuery(organizationId);

  switch (query.status) {
    case 'idle':
    case 'loading':
      return <span className="tw-loading" />;

    case 'success':
      return query.data.name;

    case 'error':
    default:
      return <em>{__('Error')}</em>;
  }
};

export default OrganizationName;
