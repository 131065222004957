/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentProps, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { sameWidthModifier } from './utils';
import { ResourceSearchResult } from './common/types';
import ResourceSearchResults from './components/results';
import { useClickAway } from 'react-use';

const getSearchInputPlaceholder = (type: ResourceSearchResult['type']): string => {
  switch (type) {
    case 'group': return __('Search groups');
    case 'reflection': return __('Search reflections');
    default: return __('Search');
  }
};

const ResourcesSearch: React.FC<{
  filter: ComponentProps<typeof ResourceSearchResults>['filter'];
  placeholder?: string;
  onResultClick: (result: ResourceSearchResult) => void;
  closeOnClick?: boolean;
}> = (props): JSX.Element => {
  const {
    filter,
    placeholder = getSearchInputPlaceholder(filter.type),
    onResultClick,
    closeOnClick,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    strategy: 'fixed',
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      {
        name: 'preventOverflow',
        options: {
          rootBoundary: 'viewport',
        },
      },
      sameWidthModifier,
    ],
  });

  const [state, setState] = useState({
    resultsOpen: false,
    query: '',
  });

  useClickAway(containerRef, () => {
    setState((s) => ({ ...s, resultsOpen: false }));
  });

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setState((s) => ({ ...s, query: value, resultsOpen: true }));
  };

  const handleQueryFocus = () => {
    setState((s) => ({ ...s, resultsOpen: true }));
  };

  const resetQuery = () => {
    setState((s) => ({ ...s, query: '' }));
  };

  const handleContainerKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
      setState((s) => ({ ...s, resultsOpen: false }));
    }
  };

  return (
    <div ref={containerRef} className="tw-text-base" onKeyDown={handleContainerKeyDown}>
      <div
        ref={setReferenceElement}
        className="tw-relative"
      >
        <div className="tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-left-0 tw-px-4">
          <i className="fa fa-search" />
        </div>
        <input
          className={`tw-px-10 tw-bg-base tw-input tw-input-bordered tw-w-full tw-placeholder-tw-base-300 focus:tw-outline-none ${state.resultsOpen ? 'tw-shadow-lg' : ''}`}
          placeholder={placeholder}
          value={state.query}
          onChange={handleQueryChange}
          onFocus={handleQueryFocus}
        />
        {state.query.length >= 1 ? (
          <button type="button" className="tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-right-0 tw-px-4 tw-h-full hover:tw-opacity-80" onClick={resetQuery}>
            <i className="fa fa-times" />
          </button>
        ) : null}
      </div>

      {state.resultsOpen ?(
        <div ref={setPopperElement} className="tw-flex tw-flex-col tw-max-h-80" style={styles.popper} {...attributes.popper}>
          <ResourceSearchResults
            filter={filter}
            query={state.query}
            onResultClick={(...args) => {
              if (closeOnClick) {
                setState((s) => ({ ...s, resultsOpen: false }));
              }

              onResultClick(...args);
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ResourcesSearch;
