import { z } from 'zod';
import { ChartsData } from '../../../modules/metrics/types';
import { OrganizationID } from '../../types/Atlas';
import ApiError from '../error';
import { jsonHeaders } from '../routes';
import { toQuerystring } from '../utils';

const metricsChartsPath = '/api/v0/metrics/charts';

export type FindMetricsChartsParams = {
  organization_id: OrganizationID;
};

export interface FindMetricsChartsArguments {
  params: FindMetricsChartsParams;
}

const findMetricsChartsResponse = z.object({
  data: z.object({
    organization_id: z.number(),
    organization_name: z.string(),
    charts_data: z.unknown().transform((v) => v as ChartsData),
    min_date: z.date({ coerce: true }).optional(),
    max_date: z.date({ coerce: true }).optional(),
  }),
});

export type FindMetricsChartsResponse = z.infer<
  typeof findMetricsChartsResponse
>;

export const findMetricsCharts = async (
  args: FindMetricsChartsArguments,
): Promise<FindMetricsChartsResponse> => {
  const { params } = args;

  const url = [metricsChartsPath, toQuerystring(params)].join('?');

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: jsonHeaders,
  });

  if (!response.ok) {
    throw new ApiError(response.status, 'findMetricsCharts');
  }

  return findMetricsChartsResponse.parse(await response.json());
};
