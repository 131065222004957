import React from 'react';

// UI
import {
  CartesianGrid,
  DotProps,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

// Utils
import { secondsToTimestamp } from '../../../../common/utils/utils';
import { pickColor } from '../../../../common/utils/colors';
import * as Atlas from '../../../../common/types/Atlas';
import { lineLegendFormatter } from '../legendFormatter';
import useVideoPlayer from '../../../jwplayer/useVideoPlayer';

interface Payload {
  time: number;
  videoId: Atlas.VideoID;
}

interface FormTimerLineChartProps {
  formElement: Atlas.FormElement;
  formAnswers?: Atlas.FormAnswer[];
}

const FormTimerLineChart = (props: FormTimerLineChartProps): JSX.Element => {
  const { formElement, formAnswers = [] } = props;

  const videoPlayer = useVideoPlayer();

  if (!('timer' in formElement.field.type)) {
    throw 'FormFieldType is incompatible with TimerFormFieldType';
  }

  const { choices } = formElement.field.type.timer;

  const handleDotClick = (
    _: DotProps,
    event: React.MouseEvent<SVGCircleElement, MouseEvent> & { payload: Payload; },
  ) => {
    if (!videoPlayer) { return; }

    const { time, videoId } = event.payload;
    const currentVideoId = videoPlayer.videoId;

    if (currentVideoId === videoId) {
      videoPlayer.seek(time / 1000);
    } else {
      videoPlayer.play(videoId, time / 1000);
    }
  };

  const data: Record<number, Record<number, Record<string, number>>> = {};

  videoPlayer?.playlist.forEach((videoId) => {
    data[videoId] = {};
  });

  formAnswers.forEach((fa) => {
    fa.timer_value?.forEach((tv) => {
      tv.intervals.forEach((interval) => {
        const vId = interval.start.video_id;
        data[vId] ||= {};

        const start = Math.floor(interval.start.time / 1000);
        data[vId][start] ||= {};
        data[vId][start][tv.choice] ||= 0;
        data[vId][start][tv.choice] += 1;

        const end = Math.floor(interval.end.time / 1000);
        data[vId][end] ||= {};
        data[vId][end][tv.choice] ||= 0;
        data[vId][end][tv.choice] -= 1;
      });
    });
  });

  const chartsData = Object.entries(data).map(([videoId, times]) => {
    const data: Array<Record<string, number>> = [];

    Object.entries(times).reduce((prev, [time, choices]) => {
      const next: {
        time: number;
        videoId: Atlas.VideoID;
      } & Record<string, number> = {
        ...prev,
        time: Number(time),
        videoId: Number(videoId),
      };

      Object.entries(choices).forEach(([k, v]) => {
        next[k] ||= 0;
        next[k] += v;
      });

      data.push(next);
      return next;
    }, {
      time: 0,
      ...choices.reduce<Record<string, number>>((acc, choice) => {
        acc[choice] = 0;
        return acc;
      }, {}),
    });

    return { videoId, data };
  });

  return (
    <div>
      {chartsData.map(({ videoId, data }) => (
        data.length ? (
          <ResponsiveContainer height={200} width="100%" key={videoId}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" type="number" scale="time" name={__('Time')} tickFormatter={(n) => secondsToTimestamp(Number(n))} />

              <YAxis allowDecimals={false} type="number" name={__('Count')}>
                <Label angle={-90} value={__('Count')} position="insideLeft" offset={20} style={{ textAnchor: 'middle' }} />
              </YAxis>

              <Tooltip labelFormatter={(n) => secondsToTimestamp(Number(n))} />
              <Legend formatter={lineLegendFormatter} />

              {choices.map((c, i) => (
                <Line
                  key={c}
                  dataKey={c}
                  connectNulls
                  stroke={pickColor(i)}
                  activeDot={{ onClick: handleDotClick }}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        ) : null
      ))}
    </div>
  );
};

export default FormTimerLineChart;
