import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { pickExtendedColor } from '../utils/colors';

const HorizontalBarChart = ({
  data,
  height,
}: {
  data: Array<{ name: string; value: number }>;
  height: number;
}) => (
  <ResponsiveContainer width="100%" height={height}>
    <BarChart
      layout="vertical"
      height={height}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />

      <XAxis dataKey="value" type="number" />
      <YAxis dataKey="name" type="category" />

      <Tooltip />

      <Bar dataKey="value">
        {data.map((entry, index) => (
          <Cell key={`cell-${entry.name}`} fill={pickExtendedColor(index)} />
        ))}
      </Bar>
    </BarChart>
  </ResponsiveContainer>
);

export default HorizontalBarChart;
