import React from 'react';
import { MetricsCharts } from '../../common/types/Atlas';

const MetricsControls: React.FC<{
  onDateStartChange: (date: string) => void;
  onDateEndChange: (date: string) => void;
  onChartTypeChange: (chartType: string) => void;
  onChartMetricChange?: (chartMetric: string) => void;
  onExportClick: () => void;
  minDate?: string;
  maxDate?: string;
  dateStart?: string;
  dateEnd?: string;
  chartType: MetricsCharts;
  chartMetric?: string;
  chartMetricOptions?: string[];
}> = ({
  minDate,
  maxDate,
  dateStart,
  dateEnd,
  chartType,
  chartMetric,
  chartMetricOptions,
  onDateStartChange,
  onDateEndChange,
  onChartTypeChange,
  onExportClick,
  onChartMetricChange,
}) => {
  const handleDateStartChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.currentTarget;
    onDateStartChange(value);
  };

  const handleDateEndChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    onDateEndChange(value);
  };

  const handleChartTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { value } = event.currentTarget;
    onChartTypeChange(value);
  };

  const handleChartMetricChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { value } = event.currentTarget;
    onChartMetricChange?.(value);
  };

  return (
    <div className="tw-flex tw-gap-4 tw-flex-col sm:tw-flex-row sm:tw-items-end sm:tw-flex-wrap">
      <div>
        <label className="tw-input-label" htmlFor="dateStart">
          {__('Date start')}
        </label>
        <input
          type="date"
          className="!tw-input !tw-mb-0 !tw-shadow tw-w-full sm:tw-w-48"
          name="dateStart"
          value={dateStart ?? ''}
          min={minDate}
          max={maxDate}
          onChange={handleDateStartChange}
        />
      </div>

      <div>
        <label className="tw-input-label" htmlFor="dateEnd">
          {__('Date end')}
        </label>
        <input
          type="date"
          className="!tw-input !tw-mb-0 !tw-shadow tw-w-full sm:tw-w-48"
          name="dateEnd"
          value={dateEnd ?? ''}
          min={minDate}
          max={maxDate}
          onChange={handleDateEndChange}
        />
      </div>

      <div>
        <label className="tw-input-label" htmlFor="chartType">
          {__('Chart type')}
        </label>

        <div className="!tw-join">
          <select
            className="!tw-select !tw-mb-0 !tw-shadow tw-w-full sm:tw-w-48 tw-join-item"
            name="chartType"
            value={chartType}
            onChange={handleChartTypeChange}
          >
            <option value={MetricsCharts.LineCharts}>{__('Weekly')}</option>
            <option value={MetricsCharts.Aggregates}>{__('Totals')}</option>
          </select>

          {chartMetricOptions ? (
            <select
              className="!tw-select !tw-mb-0 !tw-shadow tw-w-full sm:tw-w-48 tw-join-item"
              name="chartMetric"
              value={chartMetric}
              onChange={handleChartMetricChange}
            >
              <option value="">{__('Aggregated')}</option>
              {chartMetricOptions.length ? (
                <optgroup label={__('Compare metrics')}>
                  {chartMetricOptions.map((cco) => (
                    <option key={cco} value={cco}>
                      {cco}
                    </option>
                  ))}
                </optgroup>
              ) : null}
            </select>
          ) : null}
        </div>
      </div>

      <div className="ltr:sm:tw-ml-auto rtl:sm:tw-mr-auto">
        <button
          type="button"
          className="tw-btn tw-input tw-shadow tw-gap-2 tw-w-full sm:tw-w-auto"
          onClick={() => onExportClick()}
        >
          {__('Export')}
          <i className="fa fa-download" />
        </button>
      </div>
    </div>
  );
};

export default MetricsControls;
